.menu-left {
  transition: width 0.2s;
  @apply h-full;
  @apply pr-1;
  @apply overflow-y-auto;
  z-index: 2;
}
.menu-left nav {
  @apply mt-10;
  @apply mx-2;
  @apply rounded-md;
  @apply border-b;
}
.menu-left-expanded {
  // @apply hidden;
}
.menu-left:hover .menu-left-expanded {
  display: block !important;
}
