body {
  overflow-x: hidden;
  background-color: #e8eaed;
}

.main-container {
}

label {
  @apply text-gray-600;
  @apply text-sm;
}
