// [tabview]
.p-tabview-panels {
  padding: 0px !important;
  background-color: transparent !important;
}
.p-tabview-nav {
  background-color: transparent !important;
}
.p-tabview-nav-link {
  margin-right: 2px !important;
}
// [input]
input[type="file"]::-webkit-file-upload-button,
input[type="file"]::file-selector-button {
  @apply text-white bg-gray-700 hover:bg-gray-600 font-medium text-sm cursor-pointer border-0 py-2.5 pl-8 pr-4;
  margin-inline-start: -1rem;
  margin-inline-end: 1rem;
}
